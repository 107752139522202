import React from 'react'
import styled from 'styled-components'
import { map, isEqual, get } from 'lodash'

import media from '../../../utils/media'

const ZONES = [{
  title: 'Zone Pinel',
  lines: ['Zone A Bis', 'Zone A', 'Zone B1', 'Zone B2']
}, {
  title: 'Barème de loyer Pinel',
  lines: ['17,55 €/m²', '13,04 €/m²', '10,51 €/m²', '9,13 €/m²']
}]

const Table = styled.div`
  display: flex;
  font-family: 'Source Sans Pro', sans-serif;
`

const ZoneBubble = styled.div`
  position: relative;
  font-size: 15.8px;
  color: ${({ theme }) => get(theme, 'white', '#fff')};
  background-color: ${({ theme }) => get(theme, 'skyBlue')};
  text-align: center;
  border-radius: 7px;
  padding: 12px 7px;
  width: 175px;

  ${media.greaterThan('sm')`
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 80%;
      left: 40%;
      border: 15px solid transparent;
      border-bottom-color: ${({ theme }) => get(theme, 'skyBlue', '#fff')};
      transform: rotate(180deg);
    }
  `}

  ${media.lessThan('sm')`
    font-size: 14px;
    width: 150px;
    padding: 5px;
  `}
`

const Column = styled.div`
  margin-right: 10px;
  margin-top: 7px;
  text-align: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #213EAA40;

  &:last-of-type {
    margin-right: 0;
  }
`

const Cell = styled.div`
  font-size: 19px;
  color: ${({ theme }) => get(theme, 'blue', '#fff')};
  background-color: ${({ idx, theme }) => get(theme, isEqual(idx % 2, 1) ? 'paleGrey' : 'white')};
  padding: 14px 6px;

  &:last-of-type {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  ${media.lessThan('sm')`
    font-size: 14px;
    padding: 4px 3px;
  `}
`

const ZonesColumns = () => map(ZONES, ({ title, lines }) => (
  <Column key={`column-${title}`}>
    <ZoneBubble>{title}</ZoneBubble>
    <div>
      {map(lines, (total, idx) => (
        <Cell idx={idx} key={`cell-${idx}-${total}`}>
          {total}
        </Cell>
      ))}
    </div>
  </Column>
))

const InvestmentResources = () => (
  <Table box={true}>
    <ZonesColumns />
  </Table>
)

export default InvestmentResources
