import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import PictoType from '../../../assets/type.inline.svg'
import HomeDetails from '../../molecules/HomeDetails'
import HomeContainer from '../../atoms/HomeContainer'
import PictoPriceHouse from '../../../assets/price-house.inline.svg'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PictoLocalisation from '../../../assets/localisation.inline.svg'
import PictoColContainer from '../../molecules/PictoColContainer'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const DETAILS_COL = [{
  icon: PictoType,
  title: 'La surface du bien',
  description: 'Même si cela peut paraître surprenant, un petit appartement peut générer plus de revenus qu’un grand logement ! Tout dépend en fait de l’emplacement de votre bien immobilier. Par exemple, il est souvent plus rentable de choisir des petites surfaces plutôt que des grandes dans les villes étudiantes.'
}, {
  icon: PictoPriceHouse,
  title: 'Le prix d’achat de l’appartement ou de la maison',
  description: 'Bien sûr, ce critère est pris en compte dans le calcul de rendement locatif.'
}, {
  icon: PictoLocalisation,
  title: 'L’emplacement du bien immobilier',
  description: 'Il est primordial. Vous pourrez changer l’aménagement, la décoration ou encore la disposition de votre bien immobilier, mais concernant l’emplacement, vous ne pourrez rien faire.'
}]

const ContainerDetails = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
  margin: 32px auto;
  max-width: 90%;

  ${media.greaterThan('sm')`
    gap: 32px;
    margin: 40px auto;
    max-width: fit-content;
  `}
`

const InvestmentModalities = () => (
  <HomeContainer>
    <HomeSectionTitle tag='h3'>
      Les modalités de l’investissement immobilier locatif
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Dans l’investissement immobilier locatif, l’un des volets les plus importants est le rendement.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      Celui-ci dépend de plusieurs variables, telles que :
    </HomeSectionDescription>
    <PictoColContainer details={DETAILS_COL}/>
  </HomeContainer>
)

export default InvestmentModalities
