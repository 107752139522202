import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import media from '../../../utils/media'
import Residencies from '../../molecules/Residencies'
import HomeContainer from '../../atoms/HomeContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const RESIDENCIES = [{
  title: 'Location nue',
  image: 'nude',
  html: 'Il s’agit de l’investissement immobilier le plus commun. <strong>La location nue</strong> consiste à louer un bien non meublé, sans aucune contrainte d’aménagement. L’avantage de la location nue est qu’elle peut être pratiquée partout en France, que la zone soit à flux tendu ou non.'
}, {
  title: 'Location meublée non professionnelle',
  image: 'furniture',
  html: '(&nbsp;<span><a href="/investissement-lmnp">Investissement locatif LMNP</a></span>&nbsp;)<br />Cette méthode permet de louer son bien plus cher que le prix du marché, en moyenne de 20% à 40% selon les zones. Il consiste à louer un appartement totalement meublé et équipé et confère divers avantages fiscaux. Attention, <strong>l’investissement immobilier locatif LMNP</strong> ne convient pas à tous les types de biens, il s’agit de viser plutôt les T1 et T2 en grandes métropoles.'
}, {
  title: 'Location Pinel',
  image: 'pinel',
  html: 'C’est un type d’investissement immobilier locatif qui relève d’un régime fiscal particulier, qui peut être très avantageux. <strong>La location Pinel</strong> ne s’adresse pas à tout le monde. Il faut, en effet, payer plus de 2 500 euros d’impôts par an pour en profiter véritablement. Aussi, il faudra investir dans un bien immobilier neuf pour jouir du dispositif Pinel.'
}, {
  title: 'Location Censi Bouvard',
  image: 'censi',
  html: 'Il s’agit d’un modèle d’investissement locatif qui permet de bénéficier de <span>défiscalisation</span>, mais qui ne s’adresse pas à tous les types de biens immobiliers. Dans les faits, seul l’immobilier neuf est concerné par <strong>la location Censi Bouvard.</strong>'
}]

const Container = styled.div`
  background-color: ${({ theme }) => get(theme, 'paleGrey')}; 
  padding: 56px 24px;

  ${media.greaterThan('sm')`
    padding: 72px 0;
  `}

  ${media.greaterThan('xl')`
    padding: 72px 40px;
  `}

  ${media.greaterThan('xxl')`
    padding: 72px 144px;
  `}
`

const DetailsContainer = styled.div`
  display: flex;
  margin: 32px auto;
  justify-content: center;
  flex-wrap: wrap;
  padding: 32px 24px;
  gap: 24px;


  ${media.lessThan('md')`
    align-items: center;
    flex-direction: column;
  `}

  ${media.greaterThan('md')`
    flex-wrap: wrap;
    margin: 40px auto;
    width: 85%;
  `}

  ${media.greaterThan('lg')`
    width: 95%;
  `}

`

const InvestmentTypes = () => (
  <Container>
    <HomeContainer max={1600}>
      <HomeSectionTitle tag='h3'>
        Les grands types d’investissement immobilier
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Il existe différents types d’investissement immobilier. Par ailleurs, ceux-ci sont couramment appelés des dispositifs fiscaux, en raison des régimes fiscaux qui leur sont liés.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Il apparaît, dans les faits, que chaque type d’investissement immobilier présente des spécificités, dont un régime fiscal bien défini. ll est important d’avoir conscience des principales méthodes d’investissement immobilier et de leurs spécificités afin de trouver celle qui répond à ses attentes et besoins. Les 4 grandes catégories d’investissem ent immo sont les suivantes&nbsp;:
      </HomeSectionDescription>
    </HomeContainer>
    <DetailsContainer>
      <Residencies
        residencies={RESIDENCIES} />
    </DetailsContainer>
  </Container>
)

export default InvestmentTypes
