import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import media from '../../../utils/media'
import TopSection from '../../molecules/TopSection'
import DoorInvestment from '../../molecules/DoorInvestment'
import InvestmentHero from '../../molecules/InvestmentHero'

const StyledDoorImage = styled(DoorInvestment)`
  z-index: -1;
  display: none;

  ${media.greaterThan('xl')`
    display: block;
    height: 800px; 
    top: 281px;
  `}
`

const InvestmentTopSection = () => (
  <TopSection component={StyledDoorImage}>
    <InvestmentHero />
  </TopSection>
)

InvestmentTopSection.propTypes = {
  className: PropTypes.string
}

export default InvestmentTopSection
