import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import theme from '../../../config/theme'
import media from '../../../utils/media'
import LaunchButton from '../../atoms/LaunchButton'
import HomeContainer from '../../atoms/HomeContainer'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import InvestmentResources from '../../molecules/InvestmentResources'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const Container = styled.div`
  background-color: ${({ theme }) => get(theme, 'paleGrey')}; 
  padding: 56px 0;

  ${media.greaterThan('sm')`
    padding : 72px 0;
  `}
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: start;

  ${media.greaterThan('md')`
    align-items: center;
  `}
`

const StyledIcon = styled(FontAwesomeIcon)`
  margin-right: 16px;

  ${media.lessThan('sm')`
    margin-top: 10px;
    font-size: 20px;
  `}
`

const ResourcesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  gap: 32px;

  ${media.lessThan('md')`
    flex-direction: column;
  `}

  ${media.greaterThan('sm')`
  margin-top: 40px;
  gap: 40px;
  `}
`

const TextPart = styled.div`

  ${media.lessThan('sm')`
    margin: 20px auto 0;
  `}
`

const StyledDescription = styled(HomeSectionDescription)`
  font-size: 20px;
  font-weight: 600;
  margin-top: 16px;
  margin-bottom: 8px;

  ${media.lessThan('sm')`
    font-size: 14px;
  `}
`

const BottomText = styled(HomeSectionDescription)`
  text-align: center;
  margin-top: 40px;
`

const DesktopLineBreak = styled.br`
  ${media.lessThan('sm')`
    display: none;
  `}
`

const StyledButtonContainer = styled(ButtonContainer)`
margin-top: 24px;
`

const InvestissementZoom = () => (
  <Container>
    <HomeContainer>
      <TitleContainer>
        <StyledIcon
          icon='search-plus'
          size='2x'
          color={get(theme, 'blue')} />
        <HomeSectionTitle tag='h2'>
          Zoom&nbsp;sur…&nbsp;L’investissement locatif Pinel
        </HomeSectionTitle>
      </TitleContainer>
      <HomeSectionSubtitle>
        Comme évoqué précédemment, le dispositif locatif Pinel est un régime spécial d’investissement immobilier. Il permet à une personne d’investir dans l’immobilier neuf, et de profiter d’économies d’impôts.
      </HomeSectionSubtitle>
      <ResourcesContainer>
        <TextPart>
          <HomeSectionDescription>
            À savoir que ce type d’investissement immobilier a été mis en place dans le but de favoriser la construction et le logement. L’investissement locatif Pinel aide finalement les investisseurs, tout en garantissant des logements aux personnes qui en ont besoin.
          </HomeSectionDescription>
          <StyledDescription>
            Bien sûr, la déduction fiscale liée à un investissement immobilier Pinel ne s’obtient pas sans contrepartie.
          </StyledDescription>
          <HomeSectionDescription>
            Toute personne ayant recours à ce dispositif s’engage à louer son bien sur 6 ans minimum, et ce, à un loyer plafonné. Le loyer plafonné dépend de la zone géographique dans laquelle se situe le bien. Par exemple
          </HomeSectionDescription>
        </TextPart>
        <InvestmentResources />
      </ResourcesContainer>
      <BottomText>
        <strong>Vous cherchez des informations, ou des conseils de professionnels</strong><DesktopLineBreak /> avant de réaliser un investissement locatif Pinel&nbsp;?
      </BottomText>
      <StyledButtonContainer>
        <LaunchButton
          link='/services/investissement-pinel'
          title='Contactez Folhomee'
          background='blue' />
      </StyledButtonContainer>
    </HomeContainer>
  </Container>
)

export default InvestissementZoom
