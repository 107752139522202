import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import LaunchButton from '../../atoms/LaunchButton'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const size = {
  lg: {
    height: '608'
  },
  xl: {
    height: '530'
  }
}

const InvestmentSite = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "investment-site.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <ImageSection
      size={size}
      reverse={true}
      imageData={placeholderImage}
      backgroundColor='paleGrey'>
        <HomeSectionTitle tag='p'>
          Vous l’aurez compris, l’emplacement est primordial pour un investissement immobilier rentable.
        </HomeSectionTitle>
        <HomeSectionSubtitle>
          Ainsi, il ne faut pas se fier qu’aux quartiers en vogue au moment de l’achat. Dans les zones à flux tendu notamment, les quartiers évoluent et prennent très vite en valeur. En somme, faire un investissement immobilier, c’est parfois miser sur un quartier.
        </HomeSectionSubtitle>
        <HomeSectionDescription>
          Retrouvez tous les biens qui répondent à vos critères de recherche chez Folhomee. Renseignez jusqu’à 25 critères de recherche pour une sélection affinée de biens immobiliers.
        </HomeSectionDescription>
        <ButtonContainer>
          <LaunchButton
            title='Trouvez le bien immobilier qui vous correspond'
            background='blue' />
        </ButtonContainer>
    </ImageSection>
  )
}

export default InvestmentSite
