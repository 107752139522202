import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import SEO from '../components/atoms/Seo'
import Navbar from '../components/organisms/Navbar'
import Footer from '../components/organisms/NewFooter'
import SiteSection from '../components/atoms/SiteSection'
import InvestmentZoom from '../components/organisms/InvestmentZoom'
import InvestmentSite from '../components/organisms/InvestmentSite'
import InvestmentTypes from '../components/organisms/InvestmentTypes'
import InvestmentIntro from '../components/organisms/InvestmentIntro'
import InvestmentChoice from '../components/organisms/InvestmentChoice'
import InvestmentFiscal from '../components/organisms/InvestmentFiscal'
import InvestmentTopSection from '../components/organisms/InvestmentTopSection'
import InvestmentModalities from '../components/organisms/InvestmentModalities'
import InvestmentInformation from '../components/organisms/InvestmentInformation'

const TITLE = 'Tout savoir sur l\'investissement immobilier avec Folhomee'
const DESCRIPTION = 'Investissement immobilier locatif, loi Pinel, achat de résidence principale, ... Découvrez toutes les subtilités de l\'immobilier avec Folhomee.'

const LastSection = styled(SiteSection)`
  margin-bottom: 0;
`

const InvestissementImmobilier = ({ location }) => (
  <>
    <SEO
      title={TITLE}
      description={DESCRIPTION} />
    <Navbar location={location} />
    <InvestmentTopSection />
    <SiteSection id='investissement-immobilier-locatif' $first={true}>
      <InvestmentIntro />
    </SiteSection>
    <SiteSection id='type-investissement'>
      <InvestmentTypes />
    </SiteSection>
    <SiteSection id='fiscalité-immobilier-neuf'>
      <InvestmentFiscal />
    </SiteSection>
    <SiteSection id='investissement-information'>
      <InvestmentInformation />
    </SiteSection>
    <SiteSection id='modalité-investissement-locatif'>
      <InvestmentModalities />
    </SiteSection>
    <SiteSection id='investissement-emplacement'>
      <InvestmentSite />
    </SiteSection>
    <SiteSection id='investissement-comparaison'>
      <InvestmentChoice />
    </SiteSection>
    <LastSection id='investissement-pinel'>
      <InvestmentZoom />
    </LastSection>
    <Footer noImage={true} noForm={true} />
  </>
)

InvestissementImmobilier.propTypes = {
  location: PropTypes.object.isRequired
}

export default InvestissementImmobilier
