import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import LaunchButton from '../../atoms/LaunchButton'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'

const size = {
  lg: {
    height: '420'
  }
}

const InvestmentInformation = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "concept-investment.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <ImageSection
      size={size}
      right={true}
      imageData={placeholderImage}
      backgroundColor='veryLightOrange'>
      <HomeSectionTitle tag='h3'>
        Vous souhaitez des informations pour votre investissement&nbsp;?
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        L’équipe Folhomee est à votre disposition pour vous conseiller sur votre futur investissement immobilier.
      </HomeSectionSubtitle>
      <ButtonContainer>
        <LaunchButton
          link='/services/investissement-pinel'
          title='Nous contacter' />
      </ButtonContainer>
    </ImageSection>
  )
}

export default InvestmentInformation
