import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const InvestmentIntro = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
      placeholderImage: file(relativePath: {eq: "intro-investment.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <ImageSection
      fixed={true}
      imageData={placeholderImage}
      backgroundColor='white'>
        <HomeSectionTitle tag='h2'>
          L’investissement immobilier locatif, tout ce qu’il faut savoir
        </HomeSectionTitle>
        <HomeSectionSubtitle>
          Il existe plusieurs types d’investissement immobilier. Chaque méthode présente des avantages, mais aussi des inconvénients.
        </HomeSectionSubtitle>
        <HomeSectionDescription>
          Il convient de connaître les spécificités de chaque type d’investissement immobilier pour identifier celui qui correspond le mieux à vos attentes. Folhomee vous explique les différences et vous guide dans votre choix.
        </HomeSectionDescription>
    </ImageSection>
  )
}

export default InvestmentIntro
