import React from 'react'
import styled from 'styled-components'

import HomeContainer from '../../atoms/HomeContainer'
import PictoTaskPaper from '../../../assets/task-paper.inline.svg'
import PictoCalculator from '../../../assets/calculator.inline.svg'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PictoLineContainer from '../../molecules/PictoLineContainer'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const DETAILS_LINE = [{
  icon: PictoTaskPaper,
  title: 'Régime micro',
  description: 'Au moment du calcul de l’impôt, les revenus locatifs sont réduits de 50%. Sur ces revenus réduits de moitié s’appliquent l’impôt sur le revenu, ainsi que 17% de prélèvements sociaux'
}, {
  icon: PictoCalculator,
  title: 'Régime réel',
  description: 'Toutes les charges afférentes à la location sont déduites au moment de la déclaration fiscale. Les charges déductibles peuvent être les intérêts d’emprunt, la taxe foncière, les frais de travaux, etc. Ce régime fiscal peut être très intéressant en cas de réalisation de travaux sur le bien loué.'
}]

const StyledTitle = styled(HomeSectionTitle)`
  margin: 16px 0;
`

const StyledSubtitle = styled(HomeSectionSubtitle)`
  margin: 16px 0;
`

const StyledDescription = styled(HomeSectionDescription)`
  margin: 16px 0;
`

const InvestmentFiscal = () => (
  <HomeContainer max={1100}>
    <StyledTitle tag='h3'>
      La fiscalité dans l’investissement locatif
    </StyledTitle>
    <StyledSubtitle>
      Lorsqu’il est question d’investissement immobilier locatif, la fiscalité est incontournable. Dans les faits, le choix entre les différents dispositifs disponibles se fait souvent selon les avantages fiscaux qui leur sont liés.
    </StyledSubtitle>
    <StyledDescription>
      Toute personne qui réalise un investissement immobilier locatif peut choisir entre le régime micro et le régime réel.
      <br />
      Qu’est-ce que cela signifie&nbsp;? Voici une explication simple, en quelques lignes&nbsp;:
    </StyledDescription>
    <PictoLineContainer details={DETAILS_LINE}/>
  </HomeContainer>
)

export default InvestmentFiscal
