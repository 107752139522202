import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { map, get } from 'lodash'

import theme from '../../../config/theme'
import media from '../../../utils/media'
import Header from '../../atoms/HeroHeader'
import Subtitle from '../../atoms/HeroSubtitle'
import LineBreak from '../../atoms/LineBreak'
import Container from '../../atoms/HeroContainer'
import HeroDescription from '../../atoms/HeroDescription'

const LINKS = [{
  image: 'building',
  title: 'Fiscalité de l’immobilier Neuf',
  link: '/comprendre-fiscalite'
}, {
  image: 'key',
  title: 'Gestion locative de l’immobilier Neuf',
  link: '/gestion-locative'
}, {
  image: 'money-bill',
  title: 'Défiscalisation',
  link: '/dispositif-de-defiscalisation'
}]

const StyledContainer = styled(Container)`
  max-width: 800px;
  margin: auto;
  height: 800px;

  ${media.greaterThan('xl')`
    max-width: 500px;
    margin-right: 55%;
  `}

  ${media.greaterThan('xxl')`
    max-width: 580px;
    margin-right: 50%;
  `}
`

const Description = styled(HeroDescription)`
  margin-bottom: 16px;
`

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 80%;


  ${media.greaterThan('xl')`
    margin: 0;
  `}
`

const HeroLink = styled(Link)`
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => get(theme, 'paleBlue', '#fff')};
  border-radius: 25px;
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => get(theme, 'blue', '#fff')};
  font-family: 'Source Sans Pro';
  text-decoration: none;
  margin: 0 0 16px;
`

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: auto;
  margin-right: 8px;
`

const Links = () => map(LINKS, ({ title, link, image }) => (
  <HeroLink key={`${title}-${link}`} to={link} title={title}>
    <ImageContainer>
      <FontAwesomeIcon icon={image} color={get(theme, 'blue', '#fff')}/>
    </ImageContainer>
    <div>
      {title}
    </div>
  </HeroLink>
))

const InvestmentHero = () => (
  <StyledContainer>
    <Header max={100}>
      Investissement immobilier :<LineBreak /> comment et pourquoi se lancer&nbsp;?
    </Header>
    <Subtitle>
      Réaliser un investissement immobilier présente des avantages.
    </Subtitle>
    <Description>
      Quelles que soient les volontés du propriétaire, il tirera certainement des bénéfices de son achat. Folhomee, agrégateur de biens immobiliers, se tient à votre disposition pour vous aider à investir dans l’immobilier de façon rentable et pérenne.
    </Description>
    <Description>
      Pour aller plus loin&nbsp;:
    </Description>
    <LinksContainer>
      <Links />
    </LinksContainer>
  </StyledContainer>
)

export default InvestmentHero
