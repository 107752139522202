import React from 'react'
import styled from 'styled-components'

import PictoMoney from '../../../assets/money.inline.svg'
import PictoEnergy from '../../../assets/energy.inline.svg'
import LaunchButton from '../../atoms/LaunchButton'
import HomeContainer from '../../atoms/HomeContainer'
import PictoIsolation from '../../../assets/isolation.inline.svg'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PictoLineContainer from '../../molecules/PictoLineContainer'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const DETAILS_LINE = [{
  icon: PictoMoney,
  title: 'Avantages fiscaux',
}, {
  icon: PictoIsolation,
  title: 'Meilleure isolation thermique',
}, {
  icon: PictoEnergy,
  title: 'Performances écologiques améliorées',
}]

const BottomText = styled(HomeSectionDescription)`
  text-align: center;
  margin-top: 40px;
  margin-bottom: 0;
`

const InvestmentChoice = () => (
  <HomeContainer max={1400}>
    <HomeSectionTitle tag='p'>
      Choisir entre le neuf et l’ancien pour un investissement immobilier
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Le charme de l’ancien séduit souvent, mais l’immobilier neuf présente des atouts avec lesquels il est difficile de rivaliser.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      Par exemple, en réalisant un investissement immobilier dans le neuf, vous bénéficierez&nbsp;:
    </HomeSectionDescription>
    <PictoLineContainer details={DETAILS_LINE}/>
    <HomeSectionDescription>
      <strong>Attention, certains types d’investissement sont réservés aux biens neufs.</strong> Pensez aussi à vous renseigner sur <span>les garanties et assurances liées à l’investissement immobilier locatif</span> avant de vous lancer.
    </HomeSectionDescription>
    <BottomText>
      <strong>Besoin de conseils&nbsp;?</strong><br/>Nous sommes là pour vous guider
    </BottomText>
    <ButtonContainer>
      <LaunchButton
        link='/services/investissement-pinel'
        title='Contactez-nous' />
    </ButtonContainer>
  </HomeContainer>
)

export default InvestmentChoice
